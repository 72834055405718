<template>
    <div>
        <ts-page-title
            :title="$t('assignDriver.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('assignDriver.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-loading-banner :loading="loading">
            <ts-panel>
                <ts-panel-wrapper>
                    <div class="tw-flex tw-justify-between">
                        <div class="tw-flex tw-space-x-3">
                            <div class="tw-flex tw-items-center tw-space-x-3">
                                <a-button
                                    type="primary"
                                    @click="addNew"
                                    v-if="$can('create-assign-drivers')"
                                >
                                    {{ $t("addNew") }}
                                </a-button>
                                <a-range-picker
                                    v-model="dateRange"
                                    :allowClear="false"
                                    format="DD-MM-YYYY"
                                    valueFormat="DD-MM-YYYY"
                                    style="width: 200px"
                                    @change="fetchData()"
                                />
                            </div>
                        </div>
                        <a-tooltip :title="$t('assignDriver.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('assignDriver.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </ts-panel-wrapper>
                <div class="tw-overflow-x-scroll">
                    <ts-table
                        :columns="columns"
                        :records="resources"
                        :loading="loading"
                    >
                        <template v-slot="{ record }">
                            <td class="tw-text-left">
                                {{ record.transaction_date }}
                            </td>
                            <td class="tw-text-center">
                                {{ record.voucher_no }}
                            </td>
                            <td class="tw-text-center">
                                {{ record.driver_code }}
                            </td>
                            <td class="tw-text-left">
                                {{ record.driver_name }}
                            </td>
                            <td class="tw-text-left">
                                {{ record.user_name }}
                            </td>
                            <td class="tw-text-center">
                                <a href="#" @click.prevent="toPdf(record)">
                                    <a-tooltip placement="left">
                                        <template v-slot:title>{{
                                            $t("pdf")
                                        }}</template>
                                        <i
                                            class="fas fa-file-pdf tw-text-red-500"
                                        ></i>
                                    </a-tooltip>
                                </a>
                            </td>
                        </template>
                    </ts-table>
                </div>
                <div class="d-flex justify-content-end tw-p-4">
                    <ts-pagination
                        v-model="pagination"
                        @navigate="fetchData"
                    ></ts-pagination>
                </div>
                <a-modal
                    :title="$t('pdf')"
                    width="50%"
                    v-model="preview_pdf"
                    :footer="null"
                >
                    <ts-preview-pdf v-model="waiting" :src="src" />
                </a-modal>
            </ts-panel>
        </ts-loading-banner>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
    name: "driverCompensate",
    data() {
        return {
            loading: false,
            preview_pdf: false,
            waiting: false,
            src: ""
        };
    },
    computed: {
        ...mapState("agency/assignDriver", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.agency.assignDriver.search;
            },
            set(newValue) {
                this.$store.commit("agency/assignDriver/SET_SEARCH", newValue);
                this.$store.commit("agency/assignDriver/RESET_CURRENT_PAGE");
            }
        },
        dateRange: {
            get() {
                return this.$store.state.agency.assignDriver.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/assignDriver/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("assignDriver.transactionDate"),
                    style: "text-align:left", sortKey: 'transaction_date'
                },
                {
                    name: this.$t("assignDriver.voucherNo"),
                    style: "text-align:center", sortKey: 'voucher_no'
                },
                {
                    name: this.$t("assignDriver.driverCode"),
                    style: "text-align:center", sortKey: 'driver_code'
                },
                {
                    name: this.$t("assignDriver.driverName"),
                    style: "text-align:left", sortKey: 'driver_name'
                },
                {
                    name: this.$t("assignDriver.createdBy"),
										style: 'width: 80px', sortKey: 'user_name'
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 40px"
                }
            ];
        }
    },
    methods: {
        addNew() {
            this.$router.push({ name: "driver-assign-create" });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("agency/assignDriver/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        toPdf(record) {
            this.preview_pdf = true;
            this.waiting = true;
            this.$store
                .dispatch("agency/assignDriver/toPdf", record.voucher_no)
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/assignDriver/RESET_STATE");
        next();
    }
};
</script>
